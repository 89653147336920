/**
 * Tile Slider
 * @description : Swiper for the Tile Slider module.
 */

(function () {

    var tileSliderEls = document.querySelectorAll('.js-tile-slider');

    tileSliderEls.forEach(function (tileSliderEl) {

        var swiperEl = tileSliderEl.querySelector('.js-tile-slider__swiper');

        if (swiperEl) {
            var scrollbarEl = tileSliderEl.querySelector('.js-tile-slider__scrollbar');
            new Swiper(swiperEl, {
                spaceBetween: 16,
                slidesPerView: 1,
                direction: 'horizontal',
                loop: false,
                breakpoints: {
                    [window.breakpoints['sm']]: {
                        freeMode: true,
                        freeModeMomentumRatio: 0.3,
                        scrollbar: {
                            dragSize: 200
                        },
                        slidesPerView: 1.5
                    },
                    [window.breakpoints['md']]: {
                        scrollbar: {
                            dragSize: 400
                        },
                        slidesPerView: 2
                    },
                    [window.breakpoints['lg']]: {
                        slidesPerView: 3
                    },
                    [window.breakpoints['xl']]: {
                        slidesPerView: 3.5
                    }
                },
                scrollbar: {
                    el: scrollbarEl,
                    draggable: true,
                    dragClass: 'js-tile-slider__scrollbar-drag',
                    dragSize: 100,
                }
            });
        }

    });
})();